<template>
    <div class="bg-primary">
        <div class="max-w-xl mx-auto w-full">
            <video-component
                :sessionData="{ sessionId: null, title: 'Live stream' }"
                :options="{
                    autoplay: true,
                    controls: true,
                    sources: [
                        {
                            src:
                                'https://d1acq6z9otp7mh.cloudfront.net/out/v1/e48cc8bc05ca400390b7f4c09aa2620a/index.m3u8',
                            type: 'application/x-mpegURL'
                        }
                    ],
                    poster: ''
                }"
            />
        </div>
    </div>
</template>

<script>
import VideoComponent from "@/components/shared/VideoComponent.vue";

export default {
    name: "StreamVideoLive",
    components: {
        VideoComponent
    },
    created() {
        // Not sure why scoped style is not working so:
        window.document.querySelector("html").classList.add("bg-primary");
    },
    beforeDestroy() {
        window.document.querySelector("html").classList.remove("bg-primary");
    }
};
</script>
<style lang="scss" scoped>
@import "../styles/views/streamvideolive";
</style>
