var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-primary"},[_c('div',{staticClass:"max-w-xl mx-auto w-full"},[_c('video-component',{attrs:{"sessionData":{ sessionId: null, title: 'Live stream' },"options":{
                autoplay: true,
                controls: true,
                sources: [
                    {
                        src:
                            'https://d1acq6z9otp7mh.cloudfront.net/out/v1/e48cc8bc05ca400390b7f4c09aa2620a/index.m3u8',
                        type: 'application/x-mpegURL'
                    }
                ],
                poster: ''
            }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }